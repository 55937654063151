import {
  GpsControl,
  LayerControl,
  MapButtonPanel,
  MeasureToolControl,
  RotateControl,
  SpotlightControl,
  ZoomControl,
} from '@griegconnect/krakentools-react-kmap'

import { useConfig } from '@griegconnect/krakentools-react-kraken-app'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { MapController } from '../../map-controllers/MapController'

type LiveMapControllerProps = {
  tenantId?: string
}

export const LiveMapController = ({ tenantId }: LiveMapControllerProps) => {
  const config = useConfig()

  const theme = useTheme()

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <MapButtonPanel
        region="top"
        bottomOffset={-100}
        startElements={[
          <>
            {tenantId && (
              <Box
                width={350}
                sx={{
                  mt: 2.4,
                }}
              >
                <SpotlightControl
                  isAbsolute={false}
                  placeholder="Search (S)"
                  searchitApiUrl={config.api.searchApiBaseUrl}
                  activeTenantId={tenantId}
                  autoFocus
                />
              </Box>
            )}
          </>,
        ]}
      />

      <MapButtonPanel
        bottomOffset={0}
        region="right"
        startElements={[
          <Box
            sx={{
              marginBottom: isSmallDevice ? 2 : 0,
            }}
          >
            <RotateControl key="rotate" />
          </Box>,
          <Box
            sx={{
              marginBottom: isSmallDevice ? 2 : 0,
            }}
          >
            <GpsControl key="gps" />
          </Box>,
          <ZoomControl key="zoom" />,
        ]}
      />

      <MapButtonPanel
        bottomOffset={0}
        region="left"
        startElements={[
          <Box
            sx={{
              marginBottom: isSmallDevice ? 2 : 0,
            }}
          >
            <MeasureToolControl key="measureToolControl" />
          </Box>,

          <LayerControl controls={['assets', 'live']} title={'Layers'} key="layer" />,
          <Box
            sx={{
              marginTop: isSmallDevice ? 2 : 0,
            }}
          >
            <MapController key="maps" />
          </Box>,
        ]}
      />
    </>
  )
}
