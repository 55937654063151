import { GeoJsonProperties } from 'geojson'
import { JSONSchema7, JSONSchema7Definition } from 'json-schema'
import JSONPointer from 'jsonpointer'
import { Asset, AssetType } from '../apis/dtos/KindsDto'

const schemaProp = (schema?: JSONSchema7Definition | JSONSchema7Definition[] | null, key?: keyof JSONSchema7) => {
  if (key && schema && schema !== true && !Array.isArray(schema)) {
    return schema[key]
  }
}

export type AssetDisplayValue = {
  key: string
  keyDescription: string
  items?: AssetDisplayValue[][] | AssetDisplayValue[]
  value?: string
  valueDescription?: string
}

type One = {
  const: string
  title: string
  description: string
}

// "One" type guard
const isOneArray = (value: any): value is One[] => {
  return (
    Array.isArray(value) &&
    value.every(
      (item) =>
        typeof item === 'object' &&
        item !== null &&
        typeof item.const === 'string' &&
        typeof item.title === 'string' &&
        typeof item.description === 'string'
    )
  )
}

const mapAssetPropToDisplayValue = (
  key: string,
  value: any,
  schema?: JSONSchema7 | null
): AssetDisplayValue | undefined => {
  if (!value) return
  switch (schema?.type) {
    case 'string':
    case 'number':
    case 'integer':
      const oneOfRaw = schemaProp(schema, 'oneOf')
      const oneOf = isOneArray(oneOfRaw) ? oneOfRaw : undefined
      const theOne = oneOf?.find((one) => one.const === value)
      let val = value

      if (schema.type === 'number' && value) {
        val = new Intl.NumberFormat().format(Number(Number(value).toPrecision(14)))
      } else if (schema.type === 'integer' && value) {
        val = Number(Number(value).toFixed(0))
      }
      let displayValue = theOne?.title || val
      if (value) {
        switch (schema.format) {
          case 'date':
            displayValue = new Date(value).toLocaleDateString()
            break
          case 'iso-time':
            displayValue = new Date(value).toLocaleString()
            break
          default:
        }
      }
      return {
        key: schema?.title || key,
        keyDescription: schema?.description || '',
        value: displayValue,
        valueDescription: theOne?.description || '',
      }
      break
    case 'boolean':
      return {
        key,
        keyDescription: schema?.description || '',
        value: value === true ? 'Yes' : value === false ? 'No' : '-',
      }
      break
    case 'array':
      switch (schemaProp(schema?.items, 'type')) {
        case 'object':
          const props = schemaProp(schema?.items, 'properties')
          if (props) {
            const items = (value as Array<any>)
              .map((item) =>
                Object.keys(item)
                  .map((itemKey) => {
                    const itemSchemaProps = schemaProp(schema?.items, 'properties')
                    if (itemSchemaProps) {
                      return (
                        mapAssetPropToDisplayValue(
                          itemKey,
                          item[itemKey],
                          schemaProp(itemSchemaProps as JSONSchema7, itemKey as keyof JSONSchema7) as JSONSchema7
                        ) || undefined
                      )
                    }
                  })
                  .filter((i) => !!i)
              )
              .filter((i) => !!i) as AssetDisplayValue[] | AssetDisplayValue[][]
            const description = schemaProp(schema?.items, 'description')
            return {
              key,
              keyDescription: description && description !== true ? (description as string) : '',
              items: items,
            }
          }
          break
        case 'string':
          const description = schemaProp(schema?.items, 'description')
          const oneOfRawArray = schemaProp(schema?.items, 'oneOf')
          const oneOfArray = isOneArray(oneOfRawArray) ? oneOfRawArray : undefined
          const items = new Array<AssetDisplayValue>()
          if (value) {
            ;(value as string[]).map((item) => {
              const theOne = oneOfArray?.find((one) => item === one.const)
              items.push({
                key: theOne ? theOne.title : item,
                keyDescription: theOne?.description || '',
                value: theOne?.title,
              })
            })
          }
          return {
            key,
            keyDescription: description && description !== true ? (description as string) : '',
            items,
          }
      }
      break
    case 'object':
      const props = schemaProp(schema, 'properties') as JSONSchema7
      if (props) {
        const items = Object.keys(props)
          .map((valueKey) => {
            return mapAssetPropToDisplayValue(
              valueKey,
              value[valueKey],
              props[valueKey as keyof JSONSchema7] as JSONSchema7
            )
          })
          .filter((i) => !!i) as AssetDisplayValue[]
        if (items) {
          const description = schemaProp(schema, 'description')
          const ret = {
            key,
            keyDescription: description && description !== true ? (description as string) : '',
            items: items,
          }
          return ret
        }
      }
      break
  }
}

export const mapAssetToDisplayValues = (asset: Asset, properties: Record<string, unknown>, assetType: AssetType) => {
  const keyOrder = Object.keys(assetType.schema.properties || {})
  const primaryProps = assetType.primaryProps
    ? assetType.primaryProps
        .map((p) => p.split('/').pop())
        .sort((a, b) => (a && b ? (keyOrder.indexOf(a) > keyOrder.indexOf(b) ? 1 : -1) : 1))
        ?.map((key) => ({
          [key!]: properties?.[key!] ? properties?.[key!] : '-',
        }))
        .reduce((a, v) => ({ ...a, ...v }), {})
    : {}
  const primaryproperties = properties || {}
  const secondaryProps = Object.keys(primaryproperties)
    .sort((a, b) => (a && b ? (keyOrder.indexOf(a) > keyOrder.indexOf(b) ? 1 : -1) : 1))
    .filter((key) => !primaryProps[key])
    .reduce((a, v) => ({ ...a, [v]: properties![v] }), {})

  const primaryDisplayObjects = Object.keys(primaryProps)
    .map((key) => {
      const schemaEntry = assetType.schema.properties?.[key]

      return mapAssetPropToDisplayValue(
        key,
        properties?.[key],
        schemaEntry && schemaEntry !== true ? schemaEntry : undefined
      )
    })
    .filter((f) => !!f)

  const secondaryDisplayObjects = Object.keys(secondaryProps)
    .map((key) => {
      const schemaEntry = assetType.schema.properties?.[key]
      return mapAssetPropToDisplayValue(
        key,
        properties?.[key],
        schemaEntry && schemaEntry !== true ? schemaEntry : undefined
      )
    })
    .filter((f) => !!f)
  let subtype
  if (assetType.subtype) {
    subtype = JSONPointer.get(properties, assetType.subtype)
  }

  return {
    primary: primaryDisplayObjects as AssetDisplayValue[],
    secondary: secondaryDisplayObjects as AssetDisplayValue[],
    subtype,
    assetName: asset.name + (assetType.name ? ' ' + assetType.name : ''),
  }
}
