import { MonitorMap } from '@griegconnect/krakentools-react-kmap'
import { ListItem, ListItemText, Typography, ListItemSecondaryAction, Box, Radio } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  text: {
    paddingLeft: theme.spacing(1.5),
    opacity: 0.6,
    pointerEvents: 'none',
  },
  actions: {
    right: theme.spacing(1),
  },
  radio: {
    padding: theme.spacing(1),
  },
}))

type MapItemProps = {
  mapConfig: MonitorMap
  active: boolean
  onSelectMap: (mapId: string) => void
}

export const MapItem = (props: MapItemProps) => {
  const { mapConfig, active, onSelectMap } = props
  const classes = useStyles()

  return (
    <ListItem>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body2',
          color: 'textPrimary',
          component: 'div',
        }}
      >
        <Typography className={classes.text}>{mapConfig.name}</Typography>
      </ListItemText>
      <ListItemSecondaryAction className={classes.actions}>
        <Box display="flex">
          <Radio
            checked={active}
            value={mapConfig.id}
            onChange={() => onSelectMap(mapConfig.id)}
            className={classes.radio}
          />
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
