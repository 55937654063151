import { DateTime } from 'luxon'

const timeFormats = {
  short: DateTime.TIME_SIMPLE,
  medium: DateTime.TIME_WITH_SECONDS,
  long: DateTime.TIME_WITH_SHORT_OFFSET,
  longer: DateTime.TIME_WITH_SHORT_OFFSET,
}
type LocaleTimeFormat = keyof typeof timeFormats

const dateFormats = {
  short: DateTime.DATE_SHORT,
  medium: DateTime.DATE_MED,
  long: DateTime.DATE_FULL,
  longer: DateTime.DATE_HUGE,
}
export type LocaleDateFormat = keyof typeof dateFormats

const dateTimeFormats = {
  short: DateTime.DATETIME_SHORT,
  medium: DateTime.DATETIME_MED,
  long: DateTime.DATETIME_FULL,
  longer: DateTime.DATETIME_HUGE,
  shortWithSeconds: DateTime.DATETIME_SHORT_WITH_SECONDS,
}
export type LocaleDateTimeFormat = keyof typeof dateTimeFormats

/**
 * Some kind of iso 8601 time, date or datetime, more info here:
 * https://moment.github.io/luxon/#/parsing?id=iso-8601
 * https://en.wikipedia.org/wiki/ISO_8601
 */
export type IsoDateOrTimeTypes = string

export const toLocaleTime = (isoTime: IsoDateOrTimeTypes, format: LocaleTimeFormat = 'short') =>
  DateTime.fromISO(isoTime).toLocaleString(timeFormats[format])
export const toLocaleDate = (isoDate: IsoDateOrTimeTypes, format: LocaleDateFormat = 'short') =>
  DateTime.fromISO(isoDate).toLocaleString(dateFormats[format])
export const toLocaleDateTime = (isoDateTime: IsoDateOrTimeTypes, format: LocaleDateTimeFormat = 'short') =>
  DateTime.fromISO(isoDateTime).toLocaleString(dateTimeFormats[format], {})