import {
  MapButtonPanelRegion,
  MapPopperButton,
  MapTranslucentPaper,
  activeMapIdSelector,
  useMapContext,
} from '@griegconnect/krakentools-react-kmap'
import { Map, Close } from '@griegconnect/krakentools-react-icons'
import { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, IconButton, List, Theme, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'
import { MapItem } from './MapItem'

type MapControllerProps = {
  region?: MapButtonPanelRegion
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 400,
    display: 'flex',
    height: 400,
    flexDirection: 'column',
    overflow: 'scroll',
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  closeButton: {
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))

export const MapController = ({ region }: MapControllerProps) => {
  const { mapIdentifierSlug, maps, kmapInstance } = useMapContext()
  const [activeMapId, setActiveMapId] = useRecoilState(activeMapIdSelector(mapIdentifierSlug))
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const renderDesktop = () => (
    <MapTranslucentPaper styleVariant="static" className={classes.paper} elevation={24}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Typography variant="h6">{'Maps'}</Typography>
        <IconButton onClick={() => setOpen(false)} className={classes.closeButton} size="large">
          <Close />
        </IconButton>
      </Box>
      <List>{renderContent()}</List>
    </MapTranslucentPaper>
  )

  const renderContent = () =>
    maps?.map((mapConfig, index) => (
      <MapItem
        key={mapIdentifierSlug + '_' + kmapInstance.mapElementId + '_map_' + mapConfig.id + '_id_' + index}
        mapConfig={mapConfig}
        active={mapConfig.id === activeMapId}
        onSelectMap={setActiveMapId}
      />
    ))

  return (
    <MapPopperButton
      icon={<Map />}
      title={'Maps'}
      mobileTitle={'Maps'}
      onClose={() => setOpen(!open)}
      open={open}
      region={region}
      mobileContent={<Box padding={4}>{renderContent()}</Box>}
      content={renderDesktop()}
    />
  )
}
